<template>
  <div>
    <h1>Clientes</h1>

    <v-btn color="secondary" class="mb-2" @click="$router.push('/novo-cliente')"
      >Novo</v-btn
    >

    <v-btn color="info" class="mb-2 ml-2" @click="showFormat = !showFormat">
      {{ showFormat ? "Não formatar" : "Formatar" }}
    </v-btn>

    <v-btn
      color="success"
      class="mb-2 ml-2"
      :loading="loading"
      @click="getCustomers()"
    >
      Atualizar tabela
      <v-icon>mdi-refresh</v-icon>
    </v-btn>

    <v-btn
      color="secondary"
      class="mb-2 ml-2"
      @click="$router.push({ name: 'customers.tde' })"
      >Com Tde</v-btn
    >

    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          label="Digite o nome para pesquisar"
          v-model="search"
          @input="getCustomers(1)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-form @submit.prevent="getCustomers(1)">
          <v-text-field
            label="Pesquisa CNPJ/CPF"
            v-model="cnpj"
            append-outer-icon="mdi-magnify"
            @click:append-outer="getCustomers(1)"
          ></v-text-field>
        </v-form>
      </v-col>
    </v-row>

    <v-data-table
      :items="customers"
      :headers="headers"
      class="elevation-1"
      hide-default-footer
      disable-pagination
      disable-sort
      dense
      :loading="loading"
    >
      <template v-slot:[`item.name`]="{ item }">
        <router-link :to="`/clientes/${item.id}`">{{ item.name }}</router-link>
      </template>
      <template v-slot:[`item.cnpj`]="{ item }">
        <span>{{ fCnpj(item.cnpj) }}</span>
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <span>{{ fPhone(item.phone) }}</span>
      </template>
      <template v-slot:[`item.postal_code`]="{ item }">
        <span>{{ fCep(item.postal_code) }}</span>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.current"
      :length="pagination.total"
      @input="onPageChange"
    ></v-pagination>

    <v-dialog v-model="editCustomer">
      <v-card>
        <v-card-title>Editar cliente</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="saveEditCustomer()">
            <v-progress-linear color="purple" v-if="saving" indeterminate />
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Nome do cliente"
                  v-model="clickedCustomer.name"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="CNPJ/CPF" v-model="clickedCustomer.cnpj" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-select
                  :items="cities"
                  item-value="id"
                  item-text="name"
                  label="Cidade*"
                  :loading="loading"
                  v-model="clickedCustomer.city_id"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="CEP"
                  v-model="clickedCustomer.postal_code"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Número"
                  v-model="clickedCustomer.number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Telefone"
                  v-model="clickedCustomer.phone"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Endereço"
                  v-model="clickedCustomer.address"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn color="secondary" :loading="saving" block type="submit"
                  >Salvar</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import http from "@/modules/http";
export default {
  data() {
    return {
      clickedCustomer: {},
      headers: [
        { text: "Nome", value: "name" },
        { text: "CNPJ/CPF", value: "cnpj" },
        { text: "Telefone", value: "phone" },
        { text: "CEP", value: "postal_code" },
      ],
      customers: [],
      pagination: {
        current: 1,
        total: 0,
      },
      loading: false,
      search: "",
      cnpj: "",
      showFormat: true,
      editCustomer: false,
      cities: [],
      saving: false,
      timer: null,
    };
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    getCities() {
      this.loading = true;
      http
        .get("api/cities")
        .then((res) => {
          this.loading = false;
          this.cities = res.data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    setEditCustomer(item) {
      if (this.cities.length < 1) {
        this.getCities();
      }
      this.clickedCustomer = item;
      this.editCustomer = true;
    },
    saveEditCustomer() {
      this.saving = true;
      http
        .put("api/customers/" + this.clickedCustomer.id, this.clickedCustomer)
        .then((res) => {
          this.saving = false;
          (this.editCustomer = false),
            this.$side({
              type: "success",
              msg: "Salvo com sucesso",
            });
        })
        .catch((err) => {
          this.saving = false;
          this.$side({
            type: "error",
            msg: "Erro ao salvar",
          });
          console.log(err);
        });
    },
    fCnpj(value) {
      if (value) {
        if (this.showFormat) {
          if (value.length === 14) {
            return value.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5"
            );
          } else {
            return value.replace(
              /^(\d{3})(\d{3})(\d{3})(\d{2})/,
              "$1.$2.$3-$4"
            );
          }
        } else {
          return value;
        }
      } else {
        return "Vazio";
      }
    },
    fPhone(value) {
      if (value) {
        if (this.showFormat) {
          if (value.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
          } else {
            return value.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
          }
        } else {
          return value;
        }
      } else {
        return "Vazio";
      }
    },
    fCep(value) {
      if (value) {
        if (this.showFormat) {
          return value.replace(/^(\d{5})(\d{3})/, "$1-$2");
        } else {
          return value;
        }
      } else {
        return "Vazio";
      }
    },
    getCustomers(isInput = 0) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        console.log("dispatch");
        this.loading = true;
        let page = isInput ? 1 : this.pagination.current;
        http
          .get(
            "api/customers?cnpj=" +
              this.cnpj +
              "&search=" +
              this.search +
              "&page=" +
              page
          )
          .then((response) => {
            this.customers = response.data.data;
            this.pagination.current = response.data.current_page;
            this.pagination.total = response.data.last_page;
            this.loading = false;
          });
      }, 500);
    },
    onPageChange() {
      this.getCustomers();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
</style>